<template>
   <v-container>
        <v-row no-gutters>
            <v-col class="py-4">Deze roadtrip bestaat niet. In onze kalender kunt u zien welke roadtrips we organiseren.</v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="py-4">
                <v-btn color="primary" block @click.stop="goToMainCalendar()">
                    Roadpursuit kalender
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
export default {
  name: 'NoRoadtrip',
  mixins: [RouteMixin],
}
</script>
<style>

</style>
