<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <NoRoadtrip v-if="!dataLoaded && !isLoading"/>
        <div v-if="dataLoaded">
            <AanmeldenLoggedIn v-if="status.loggedIn && !isLoading"
                :userId="userId"
                :roadtrip="roadtrip" 
                :orderDetails="orderDetails" 
                :existingOrders="existingOrders"
                :passengerOptions="passengerOptions"
                :vehicleOptions="vehicleOptions" />
            <div v-if="!status.loggedIn && !isLoading">
                <BannerTemplate :bannerSource="bannerImg">
                    <div class="px-2 mt-n5">
                        <v-container>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex">
                                    <span class="display-1">{{roadtrip.tripName}}</span>
                                    <span class="body-1 tag-wrap">
                                        <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                            :class="`${roadtrip.labelColor}`"
                                            text--white
                                            width="100"
                                            dense
                                            prominent
                                            text>{{roadtrip.label}}</v-alert>
                                    </span>
                                </v-col>
                                <v-col cols="12" class="">{{formatDayMonth(roadtrip.date)}}</v-col>
                            </v-row>
                            <div>
                                <v-row no-gutters>
                                    <v-col class="pt-3">
                                        <p>
                                            Heeft u al een account? Log dan eerst in.
                                        </p>
                                        <v-btn color="primary" block class="mb-3" @click="goToLogin($router.currentRoute.path)">
                                            Login
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <p class="header-title mt-2 mb-0">Account</p>
                                        <div class="pb-3 mb-2">
                                            <p>Wilt u een account aanmaken of gaat u liever verder als gast? Een account heeft onder meer de volgende voordelen:</p>
                                            <ul>
                                                <li>Altijd toegang tot routes en uw tickets</li>
                                                <li>Gemakkelijk passagiers en voertuigen toevoegen en afmelden</li>
                                                <li>U kunt zien aan welke roadtrips u heeft deelgenomen</li>
                                                <li>Per voertuig zien hoeveel kilometer u heeft meegereden</li>
                                                <li>Eenvoudig facturen bekijken en downloaden</li>
                                                <li>Foto's van uw voertuig en roadtrips gemakkelijk terugvinden</li>
                                            </ul>
                                        </div>
                                        <v-btn color="primary" block @click="goToRoadtripAanmeldenNewAccount(urlTitle, urlDate)">
                                            Maak een account aan
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="py-4">
                                        <p>
                                            Wilt u liever kijken of onze roadtrips bij u passen? Ga dan verder als gast. U krijgt dan een gastaccount die u altijd later nog kan omzetten.
                                        </p>
                                        <v-btn color="primary" block class="mb-3" @click="goToRoadtripAanmeldenGuest(urlTitle, urlDate)">
                                            Verder als gast
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </div>
                </BannerTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

import AanmeldenLoggedIn from '@/components/kalender/aanmelden-loggedin';
import NoRoadtrip from '@/components/kalender/roadtrip-not-found';
import BannerTemplate from '@/components/common/BannerTemplate';
import { OrderDetail } from '@/models/OrderDetail';

export default {
    name: 'RoadtripAanmelden',
    components: 
    {
        LoadingIcon,
        NoRoadtrip,
        AanmeldenLoggedIn,
        BannerTemplate
    },
    mixins: [DataMixin, RouteMixin, FormatMixin, MenuHelperMixin, RoadtripMixin, MetaTagsMixin],
    data: () => ({
        bannerImg: require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
        isLoading: false,
        dataLoaded: false,
        roadtrip: {
            date: "",
            title: "",
        },
        orderDetails: new OrderDetail(),
        passengerOptions: [],
        vehicleOptions: [],
        urlTitle: "",
        urlDate: "",
        productId: "",
        uniqueName: "",
        existingOrders: {
            hasMainOrder: false,
            orderDetails: []
        },
        isUserRegisteredToTrip: false
    }),
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.uniqueName = this.$route.params.trip;
                this.urlDate = this.$route.params.trip.slice(-10);
                this.urlTitle = this.$route.params.trip.slice(0, -11);

                if (this.userId != '')
                {
                    this.getProductWhenLoggedIn();
                    this.getAccountInformation();
                    this.$store.commit('setFooterVisibility', false);
                }
                else
                {
                    this. getProductWhenLoggedOut();
                     this.$store.commit('setFooterVisibility', true);
                }
            }
        }
    },
    computed: 
    {
        ...mapState('authorizedModule', ['userId']),
        ...mapState('account', ['status', 'user']),
        ...mapState({
            fuelTypes: state => state.menuHelper.fuelTypes
        })
    },
    metaInfo() {
        return {
        title: this.roadtrip.tripName ? `| ${this.roadtrip.tripName}` : `| Aanmelden`,
        meta: [
            this.getKeywords(`${this.roadtrip.label}, ${this.roadtrip.tripName}`),
            this.getMetaDescription(`${this.roadtrip.label} roadtrip`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.tripName}`, `${this.roadtrip.label} roadtrip`, `${window.location.origin}${this.bannerImg}`, this.roadtrip.label),
        ]
        };
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getProductCategories');
        this.$store.dispatch('menuHelper/getFoodRestrictions');
        this.$store.dispatch('menuHelper/getVehicleOptions');
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    methods: 
    {
        getProductWhenLoggedOut()
        {
            this.isLoading = true;
            this.$store.dispatch('productModule/getPublicProductByName', this.uniqueName)
                .then(data => {
                    this.mapRoadtrip(data);

                    // wait for few seconds when checking if user is logged in
                    if (this.userId == '')
                    {
                        this.isLoading = false;
                    }
            })
            .catch(() => { this.isLoading = false; });
        },
        getProductWhenLoggedIn()
        {
            this.isLoading = true;
            this.$store.dispatch('productModule/getPublicProductByNameWhenLoggedIn', 
                {
                    uniqueName: this.uniqueName,
                    accountId: this.userId
                })
                .then(data => {
                    this.isUserRegisteredToTrip = data.requestingAccountAlreadySignedUp;

                    // if user is registered, redirect to wijzigen
                    // because this page can be from redirection after login
                    if(this.isUserRegisteredToTrip)
                    {
                        this.goToUpComingTripWijzigen(this.uniqueName);
                    }
                    this.mapRoadtrip(data);
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        mapRoadtrip(data)
        {
            this.dataLoaded = data != "";
            if(!this.dataLoaded) return;

            this.productId = data.productId;
            this.roadtrip = this.mapRoadtripData(data);
        },
        getAccountInformation(){
            this.getPassengerOptions();
            this.getVehicleOptions();

            this.$store.dispatch('accountModule/getAccountOwnerBasePersoninfo', this.userId)
            .then((data) => {
                this.orderDetails.driver.firstname = data.firstname;
                this.orderDetails.driver.personId = data.personId;
                this.orderDetails.driver.allergies = data.foodRestrictions;
            });
        },
        getPassengerOptions()
        {
            this.$store.dispatch('accountModule/getCoRegisteredPersonsFromAccount', this.userId)
                .then(array => {
                    
                    array.forEach(person => {
                        person.name = `${person.firstname} ${person.lastName}`;
                        person.initials = this.getPersonInitials(person.firstname, person.lastName);
                        
                        // make these properties reactive
                        this.$set(person, 'hasLunch', false);
                        this.$set(person, 'hasDinner', false);
                        this.$set(person, 'hasBreakfast', false);
                        this.$set(person, 'hasHotel', false);

                        this.passengerOptions.push(person);
                    });
                })
        },
        getVehicleOptions()
        {
            this.$store.dispatch('vehicleModule/getVehiclesByAccountId', this.userId)
            .then(array => {

                array.forEach(element => {
                    this.vehicleOptions.push(element);
                });

                // set first car option as driver car 
                if(this.vehicleOptions.length > 0)
                {
                    this.$set(this.orderDetails, 'car', this.vehicleOptions[0]);
                }
            })
        },
    }
}
</script>