<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div v-if="!isLoading" class="roadtrip-aanmelden onStepMode" :class="{ 
            'allowLunch': roadtrip.packedLunchPrice  > 0, 
            'allowDinner': roadtrip.dinnerPrice > 0, 
            'hasDiscount': orderDetails.discount > 0 }">
            <v-container>
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2" class="d-flex">
                        <span class="display-1">{{roadtrip.tripName}}</span>
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                :class="`${roadtrip.labelColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{roadtrip.label}}</v-alert>
                        </span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2" class="">{{formatDayMonth(roadtrip.date)}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col sm="8" offset-sm="2" class="">
                        <LoggedInRegistration
                            :userId="userId"
                            :roadtrip="roadtrip"
                            :order="order"
                            :orderDetails="orderDetails"
                            :existingOrders="existingOrders"
                            :getFoodRestrictions="getFoodRestrictions"
                            :goto="goto"
                            :passengerOptions="passengerOptions"
                            :vehicleOptions="vehicleOptions" />
                    
                    </v-col>
                </v-row>
            </v-container>
            <BestellingInfo :existingOrders="existingOrders" :orderDetails="orderDetails" :roadtrip="roadtrip" />
        </div>
    </div>
</template>

<script>
import BestellingInfo from '@/components/kalender/bestelling-info';
import LoggedInRegistration from '@/components/roadtrip/signup/LoggedInRegistration';
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RouteMixin from "@/mixins/route.mixin";

import LoadingIcon from '@/components/common/LoadingIcon';

export default {
    name: 'AanmeldenLoggedIn',
    components: {
        BestellingInfo,
        LoadingIcon,
        LoggedInRegistration
    },
    props: {
        userId: String,
        roadtrip: Object,
        orderDetails: Object,
        passengerOptions: Array,
        vehicleOptions: Array,
        existingOrders: Object
    },
    mixins: [FormatMixin, MenuHelperMixin, RouteMixin],
    data: () => ({
        isLoading: false,
        bannerImg: require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
    }),
    methods: 
    {
        order()
        {
            this.isLoading = true;

            var passengerOrderDetails = [];
            this.orderDetails.registeredPassengers.forEach(passenger => {
                    passengerOrderDetails.push({
                        hasBreakfast: passenger.hasBreakfast,
                        hasPackedLunch: passenger.hasLunch,
                        hasDinner: passenger.hasDinner,
                        hasHotel: passenger.hasHotel,
                        personId: passenger.personId
                    });
                });

            passengerOrderDetails.push({
                hasBreakfast: this.orderDetails.driver.hasBreakfast,
                hasPackedLunch: this.orderDetails.driver.hasLunch,
                hasDinner: this.orderDetails.driver.hasDinner,
                hasHotel: this.orderDetails.driver.hasHotel,
                personId: this.orderDetails.driver.personId
            });

            var request = {
                accountId: this.userId,
                vehicleId: this.orderDetails.car.id != "" ? this.orderDetails.car.id : null,
                productId: this.roadtrip.productId,
                category: this.getProductCategoryId("Roadtrip"),
                note: this.orderDetails.notes,
                orderDetails: passengerOrderDetails,
                voucherCode: this.orderDetails.voucherCode
            }

            this.$store.dispatch('storeModule/placeOrderWithExistingAccount', request)
                .then(data => {
                    window.location = data;
            })
            .catch(error => { 
                this.$store.commit('dialog/showDialog', error);
                this.isLoading = false; 
            });
        }   
    }
}
</script>
